<template>
  <v-container>
    <v-row class="mt-2">
      <v-col cols="3">
        <h2>メールテンプレート一覧</h2>
      </v-col>
      <v-col cols="3" lg="2">
        <v-select
          label="種別"
          v-model="searchCondition.type"
          :items="mailTemplateTypeList"
          dense
          :readonly="false"
          :loading="loading"
          clearable
          @change="loadList"
        ></v-select>
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="3" lg="2">
        <v-btn small class="primary" :height="40" :width="250" :disabled="loading" @click="goto_detail('new', null)">
          <v-icon left>mdi-plus-circle</v-icon>新規メールテンプレート登録
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12" lg="12">
        <v-data-table 
          :headers="headers" 
          :items="mailTemplateList" 
          :loading="loading" 
          :dense="true" 
          :items-per-page="10" 
          :mobile-breakpoint="0" 
          :disable-pagination="true" 
          :hide-default-footer="true" 
          :fixed-header="true" 
          locale="ja-jp" 
          loading-text="読込中" 
          no-data-text="データがありません。" 
          :search="searchCondition.searchString"
          height="calc(100vh - 200px)"
        >
          <template v-slot:item="{ item, index }">
            <tr :key="item.templateId" :class="[(item.type == 0) ? 'disable-color' : '']">
              <td>{{ index+1 }}</td>
              <td>{{getMailTemplateType(item.type).text}}</td>
              <td>{{ item.templateName }}</td>
              <td>{{ item.subject }}</td>
              <td>{{ item.body }}</td>
              <td class="center">
                <v-btn small class="primary" :height="20" :width="60" :disabled="loading" @click="goto_detail('edit', item.templateId)">編集</v-btn>
                <v-btn small class="secondary ml-1" :height="20" :width="60" :disabled="loading" @click="goto_detail('delete', item.templateId)">削除</v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Debug from "../Lib/Debug";
import Mixin from "../mixins/const";
import Message from "../Lib/Message";

import MailTemplate from "../model/mailTemplate";

export default {
  mixins: [Mixin],

  data: () => ({
    loading: false,

    // テーブルヘッダー
    headers: [
      { text: "No", align: "center", sortable: true, value: "index", width: "5%" },
      { text: "種別", align: "center", sortable: true, value: "type", width: "10%" },
      { text: "テンプレート名", align: "center", sortable: true, value: "templateName", width: "15%" },
      { text: "件名", align: "center", sortable: true, value: "subject", width: "25%" },
      { text: "本文", align: "center", sortable: true, value: "body", width: "25%" },
      { text: "編集", align: "center", sortable: false, width: "20%" },
    ],
    mailTemplateList: [],
    
    // 検索条件
    searchCondition: {
      type: null,
      searchString: null,
    },
  }),

  // ライフサイクルフック
  created: function () {
    this.loadList();
  },

  mounted: async function () {},

  methods: {
    /** 表示更新 */
    loadList() {
      Debug.log("function[loadList]");
      this.loading = true;

      // メールテンプレート一覧を取得
      MailTemplate.search(this.searchCondition)
        .then(res => {
          if (res && res.data) {
            this.mailTemplateList = res.data;

            Debug.log2('mailTemplateList:', this.mailTemplateList)

            // 検索条件を保存
            this.$store.commit('setStoreSearchCondition', this.searchCondition);
          }
        })
        .catch((err) => {
          Message.err(err, '情報を取得できませんでした。[メールテンプレート一覧データ]');
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /** 詳細ページに遷移 */
    goto_detail(mode, id) {
      Debug.log("function[goto_detail]");

      this.$store.commit("setMode", mode); // 新規 or 編集 or 削除をstoreに保存
      this.$store.commit("setEditId", id); // 編集 or 削除をクリックしたテンプレートIDをstoreに保存

      // 同タブで開く
      this.$router.push("/mail_template_edit");
    },
  },

  computed: {},

  watch: {},
};
</script>

<style scoped>
.v-data-table >>> .flex {
  display: flex;
  gap: 5%;
  align-items: center;
  justify-content: center;
}

</style>
